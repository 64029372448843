import React from 'react'

const ParticipantsList = ({ participants=[], user, max_participants=0, count_remaining=0, addmore }) => {

    return (
        <div className="participantsList">
            {
                participants.map((participant, i) => {
                    return (
                        <p className="participantSingleTxt" key={i}>{participant.name}</p>
                    )
                })
            }

            <div className="participantsListActionContainer">
                <div className="participantsListActionContainerActions">
                    <button className="participantsListActionContainerAction noClick">
                        {
                            participants.length !== max_participants 
                            ?
                            <span className="participantsListActionContainerActionSpan">{participants.length} / </span>
                            :
                            <></>
                        }
                        {max_participants} Participants Added
                    </button>
                    {
                        participants.length < user.no_of_participants
                        ?
                            <button className="participantsListActionContainerAction" onClick={addmore}>Add More</button>
                        :
                            <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default ParticipantsList