import React, { useEffect, useState } from 'react';
import PreLoginPublicHeader from '../components/PreLoginPublicHeader';
import PreLoginPublicBanner from '../components/PreLoginPublicBanner';
import Footer from '../components/Footer';

const PreLoginPublicLayout = ({ user, children }) => {
    return (
        <div className="preLoginPage">
            <PreLoginPublicHeader />
            <div className="preLoginPageContentContainer">
                <div className="preLoginPageContentContainerInner">
                    {
                        user.is_claim_certificate === null
                        ?
                            <PreLoginPublicBanner user={user} />
                        :
                        <></>
                    }
                    <div className="preLoginPageContentMain">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PreLoginPublicLayout