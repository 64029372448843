import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../reducers/User';
import { logoutAdmin } from '../reducers/Admin';

import PreLoginPublicOutlet from '../layouts/PreLoginPublicOutlet';
import HomePage from '../pages/Home';
import ConfirmUserDetailsPage from '../pages/ConfirmDetails';
import InstituteDashboardPage from '../pages/InstituteDashboard';
import PublicDashboardOutlet from '../layouts/PublicDashboardOutlet';
import InstituteIndividualDashboardPage from '../pages/InstituteIndividualDashboard';
import IndividualDashboardPage from '../pages/IndividualDashboard';
import PrintIndividualPDFPage from '../pages/PrintIndividualPDF';
import PrintInstitutePDFPage from '../pages/PrintInstitutePDF';
import ValidateInstitutionCertificatePage from '../pages/ValidateInstitutionCertificate';
import ValidateIndividualCertificatePage from '../pages/ValidateIndividualCertificate';

const AppRouter = ({ user, admin }) => {

    const dispatch = useDispatch();

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [loggedInRole, setLoggedInRole] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(null);

    const checkIsLoggedIn = async () => {
        if(user.is_logged_in === false && admin.is_logged_in === false){
            setIsLoggedIn(false);
            setLoggedInRole(null);
        }
        else if(user.is_logged_in === true && admin.is_logged_in === true){
            dispatch(logoutUser());
            dispatch(logoutAdmin());
        }
        else if(user.is_logged_in === true){
            setIsLoggedIn(true);
            if(user.is_details_confirmed === true){
                setIsConfirmed(true);
            }
            else{
                setIsConfirmed(false);
            }
            setLoggedInRole("user");
        }
        else if(admin.is_logged_in === true){
            setIsLoggedIn(true);
            setLoggedInRole("admin");
        }
        else{
            console.log("Something Went Wrong!")
        }
    }

    // useEffect(() => {
    //     dispatch(logoutUser());
    // }, []);

    useEffect(() => {
        console.log("changed");
        checkIsLoggedIn();
        console.log(user);
        console.log(isLoggedIn);
    }, [user, admin]);

    return (
        <>
            {
                isLoggedIn != null
                ?
                    <Routes>
                        {
                            isLoggedIn === false
                            ?
                                <>
                                
                                    <Route path="/" element={<PreLoginPublicOutlet user={user} />}>
                                        <Route index element={<HomePage user={user} />} />
                                    </Route>
                                    <Route path="/individual-pdf/:id/:pid" element={<PrintIndividualPDFPage user={user} />} />
                                    <Route path="/institute-pdf/:id" element={<PrintInstitutePDFPage user={user} />} />
                                    <Route path="/validate/institute/:id" element={<ValidateInstitutionCertificatePage />} />
                                    <Route path="/validate/individual/:id" element={<ValidateIndividualCertificatePage />} />
                                </>
                            :
                            isLoggedIn === true && loggedInRole === "user" && isConfirmed === true
                            ?
                                <>
                                    <Route path="/" element={<PublicDashboardOutlet user={user} />}>
                                        {
                                            user.certificate_type === "institute"
                                            ?
                                                <Route index element={<InstituteDashboardPage user={user} />} />
                                            :
                                            user.certificate_type === "institute_individual"
                                            ?
                                                <Route index element={<InstituteIndividualDashboardPage user={user} />} />
                                            :
                                            user.certificate_type === "individual"
                                            ?
                                                <Route index element={<IndividualDashboardPage user={user} />} />
                                            :
                                            <></>
                                        }
                                    </Route>
                                    <Route path="/individual-pdf/:id/:pid" element={<PrintIndividualPDFPage user={user} />} />
                                    <Route path="/institute-pdf/:id" element={<PrintInstitutePDFPage user={user} />} />
                                </>
                            :
                            isLoggedIn === true && loggedInRole === "user" && isConfirmed === false
                            ?
                                <>
                                    <Route path="/" element={<PreLoginPublicOutlet user={user} />}>
                                        <Route index element={<ConfirmUserDetailsPage user={user} />} />
                                    </Route>
                                    <Route path="/individual-pdf/:id/:pid" element={<PrintIndividualPDFPage user={user} />} />
                                    <Route path="/institute-pdf/:id" element={<PrintInstitutePDFPage user={user} />} />
                                </>
                            :
                            <></>
                        }
                    </Routes>
                :
                <></>
            }
        </>
    )
}

export default AppRouter