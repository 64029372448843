import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { defaults, ToastAlert} from '../utils';
import { markHasNewData, markHasNoNewData, markSelfDeclarationSigned } from '../reducers/User';
import { REACT_APP_API_BASE_URL } from "../config"

const SelfDeclaration = ({ user }) => {
    const dispatch = useDispatch();

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const [showActions, setShowActions] = useState(true);
    
    const declarationSigned = async (status) => {
        console.log(status);
        if(status === true){
            try{
                setShowActions(false);
                const signDelarationReq = await axios.patch(`${REACT_APP_API_BASE_URL}user/mark/declaration`, {}, config);
                if(signDelarationReq.status === 200){
                    dispatch(markSelfDeclarationSigned());
                    if(user.has_new_data === true){
                        dispatch(markHasNoNewData());
                    }
                    else{
                        dispatch(markHasNewData());
                    }
                }
                else{
                    console.log("error");
                }
            }
            catch(error){
                console.log(error);
            }
        }
    }

    return (
        <div className="adminDashboardPageContentMain">
            <div className="adminDashboardPageContentHeading">
                <p className="adminDashboardPageContentHeadingTxt">Provide Self Declaration</p>
            </div>

            <div className="instCertDownloadContainer">
                <div className="publicDashboardSelfDeclrContainer">
                    <div className="publicDashboardSelfDeclrContent">
                        <div className="publicDashboardSelfDeclrContentKannada">
                            <p className="publicDashboardSelfDeclrContentKannadaTxt">ನಾನು, {defaults.capitalize(user.person_name)}, 24 ಮತ್ತು 25 ನೇ ಫೆಬ್ರುವರಿ 2024 ರಂದು ನಡೆದ ಸಂವಿಧಾನ ಮತ್ತು ರಾಷ್ಟ್ರೀಯ ಏಕತಾ ಸಮಾವೇಶದಲ್ಲಿ ಭಾಗವಹಿಸಿದ್ದೇನೆಂದು ಈ ಮೂಲಕ ಘೋಷಿಸುತ್ತೇನೆ.</p>
                            <p className="publicDashboardSelfDeclrContentKannadaTxt">ನಾನು ನೀಡಿದ ಮಾಹಿತಿಯು ನಿಜವೆಂದು ನಾನು ಪ್ರಮಾಣೀಕರಿಸುತ್ತೇನೆ. ನಾನು ನೀಡಿದ ಈ ಮಾಹಿತಿಯು ಸುಳ್ಳು ಎಂದು ಕಂಡುಬಂದಲ್ಲಿ, ಕಾನೂನಿನ ಪ್ರಕಾರ ನನ್ನ ವಿರುದ್ಧದ ಕಾನೂನು ಕ್ರಮಕ್ಕೆ ನಾನು ಜವಾಬ್ದಾರನಾಗಿರುತ್ತೇನೆ.</p>
                        </div>
                        <div className="publicDashboardSelfDeclrContentEnglish">
                            <p className="publicDashboardSelfDeclrContentEnglishTxt">I, {defaults.capitalize(user.person_name)}, hereby declare that, I have participated in the Constitution and National Unity Conference on 24th and 25th February 2024.</p>
                            <p className="publicDashboardSelfDeclrContentEnglishTxt">I certify that the information given by me is true. In case this information given by me is found false, I am liable for a legal action against me in accordance with law.</p>
                        </div>
                    </div>
                    {
                        showActions 
                        ?
                            <div className="publicDashboardSelfDeclrActions">
                                <div className="publicDashboardSelfDeclrActionsInner">
                                    <button className="selfDeclarationPopUpContentAction agree" type="button" onClick={() => declarationSigned(true)}>agree & proceed</button>
                                    <button className="selfDeclarationPopUpContentAction cancel" type="button" onClick={() => declarationSigned(false)}>cancel</button>
                                </div>
                            </div>
                        :
                            <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelfDeclaration