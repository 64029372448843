import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from "../../config"

const PrintIndividualPDFPage = ({ user }) => {
    const params = useParams();

    console.log(params);

    const [participant, setParticipant] = useState(null);
    const [qrCode, setQrCode] = useState(null);

    const generateCertificateHandler = async () => {
        try{
            const getPreviewCertificatesReq = await axios.get(`${REACT_APP_API_BASE_URL}participant/${params.id}/${params.pid}`);
            if(getPreviewCertificatesReq.status === 200){
                console.log(getPreviewCertificatesReq.data.data.participant);
                setParticipant(getPreviewCertificatesReq.data.data.participant);
                setQrCode(getPreviewCertificatesReq.data.data.qr_code);
            }
            else{
                console.log("error");
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        generateCertificateHandler();
    }, []);

    useEffect(() => {
        console.log(participant);
    }, [participant]);

    return (
        <div className="certificatePage">
            <div className="certificatePageMain">
                <div className="certificatePageName">
                    {
                        participant?.certificate_language === "KANNADA" 
                        ?
                            <div className="certificatePageNameKannada">
                                <p className="certificatePageNameKannadaTxt" style={{
                                    fontSize: participant?.name.length < 15 ? "2.5em" : "2em"
                                }}>{participant?.name}</p>
                            </div>
                        :
                        participant?.certificate_language === "ENGLISH" 
                        ?
                            <div className="certificatePageNameEnglish">
                                <p className="certificatePageNameEnglishTxt" style={{
                                    fontSize: participant?.name.length < 20 ? "2.5em" : "2em"
                                }}>{participant?.name}</p>
                            </div>
                        :
                            <div className="certificatePageNameEnglish">
                                <p className="certificatePageNameEnglishTxt" style={{
                                    fontSize: participant?.name.length < 20 ? "2.5em" : "2em"
                                }}>{participant?.name}</p>
                            </div>
                    }
                </div>
                <div className="certificateInstitutePageQrCode">
                    <div className="certificateInstitutePageQrCodeImage">
                        <img src={qrCode} className="certificateInstitutePageQrCodeImg" />
                    </div>
                </div>
                <div className="certificatePageImage">
                    <img src="/images/certificate-print.webp" className="certificatePageImg" />
                </div>
            </div>
        </div>
    )
}

export default PrintIndividualPDFPage