import React from 'react'
import { useSelector } from "react-redux";
import './styles/globals.scss';
import './styles/all.min.css';
import AppRouter from './navigation/AppRouter';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const user = useSelector((state) => state.user.value);
  const admin = useSelector((state) => state.admin.value);
  return (
    <>
      <AppRouter user={user} admin={admin} />
      <Toaster />
    </>
  )
}

export default App