import React from 'react'

const NoParticipants = ({ count=0, action= ()=> console.log('') }) => {
    return (
        <div className="noDataContainer">
            <div className="noDataContainerInner">
                <div className="noDataContainerIcon">
                    <i className="fa-regular fa-face-meh noDataContainerIco"></i>
                </div>
                <div className="noDataContainerHeading">
                    <p className="noDataContainerHeadingTxt">No participants added yet!</p>
                </div>
                <div className="noDataContainerDescription">
                    <p className="noDataContainerDescriptionTxt">Please update the list of participants to generate the certificates!<br />You are allowed to add upto {count} participants based on your details provided at the time of registration.</p>
                </div>
                <div className="noDataContainerActions">
                    <button className="noDataContainerAction" onClick={action}>Add Participants</button>
                </div>
            </div>
        </div>
    )
}

export default NoParticipants