import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { defaults, ToastAlert} from '../utils';
import { markHasNewData, markHasNoNewData, updateLanguage } from '../reducers/User';
import { REACT_APP_API_BASE_URL } from "../config"

const SelectCertificateLanguage = ({ user }) => {

    const dispatch = useDispatch();

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const [showActions, setShowActions] = useState(true);
    
    const selectLang = async (lang) => {
        try{
            setShowActions(false);
            const updateLanguageReq = await axios.patch(`${REACT_APP_API_BASE_URL}user/set/language`, {language: lang}, config);
            if(updateLanguageReq.status === 200){
                dispatch(updateLanguage({language: lang}));
                if(user.has_new_data === true){
                    dispatch(markHasNoNewData());
                }
                else{
                    dispatch(markHasNewData());
                }
            }
            else{
                console.log("error");
            }
        }
        catch(error){
            console.log(error);
        }
    }

    return (
        <div className="publicDashboardSelectLangContentMain">
            <div className="publicDashboardSelectLangContentHeading">
                <p className="publicDashboardSelectLangContentHeadingTxt">Your certificates will be generated in English. Click proceed to continue.</p>
            </div>

            <div className="publicDashboardSelectLangActions">
                {
                    showActions 
                    ?
                    <div className="publicDashboardSelectLangActionsInner">
                        {/* <button className="publicDashboardSelectLangAction" type="button" onClick={() => selectLang("KANNADA")}>Kannada</button> */}
                        <button className="publicDashboardSelectLangAction" type="button" onClick={() => selectLang("ENGLISH")}>Proceed</button>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default SelectCertificateLanguage