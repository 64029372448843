import axios from 'axios';
import React, { useState, useEffect } from 'react'
import ToastAlert from '../utils/ToastAlert';
import { toast } from 'react-hot-toast';

import { REACT_APP_API_BASE_URL, REACT_APP_SMALL_NAME_LENGTH } from "../config"

const AddParticipants = ({ user, participantsAdded }) => {

    const [uploadType, setUploadType] = useState("excel");

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const [person1Name, setPerson1Name] = useState("");
    const [isPerson1NameError, setIsPerson1NameError] = useState(false);
    const [person1NameErrorMessage, setPerson1NameErrorMessage] = useState("");

    const [person2Name, setPerson2Name] = useState("");
    const [isPerson2NameError, setIsPerson2NameError] = useState(false);
    const [person2NameErrorMessage, setPerson2NameErrorMessage] = useState("");

    const [person3Name, setPerson3Name] = useState("");
    const [isPerson3NameError, setIsPerson3NameError] = useState(false);
    const [person3NameErrorMessage, setPerson3NameErrorMessage] = useState("");

    const [person4Name, setPerson4Name] = useState("");
    const [isPerson4NameError, setIsPerson4NameError] = useState(false);
    const [person4NameErrorMessage, setPerson4NameErrorMessage] = useState("");

    const [person5Name, setPerson5Name] = useState("");
    const [isPerson5NameError, setIsPerson5NameError] = useState(false);
    const [person5NameErrorMessage, setPerson5NameErrorMessage] = useState("");

    const [person6Name, setPerson6Name] = useState("");
    const [isPerson6NameError, setIsPerson6NameError] = useState(false);
    const [person6NameErrorMessage, setPerson6NameErrorMessage] = useState("");

    const [person7Name, setPerson7Name] = useState("");
    const [isPerson7NameError, setIsPerson7NameError] = useState(false);
    const [person7NameErrorMessage, setPerson7NameErrorMessage] = useState("");

    const [person8Name, setPerson8Name] = useState("");
    const [isPerson8NameError, setIsPerson8NameError] = useState(false);
    const [person8NameErrorMessage, setPerson8NameErrorMessage] = useState("");

    const [person9Name, setPerson9Name] = useState("");
    const [isPerson9NameError, setIsPerson9NameError] = useState(false);
    const [person9NameErrorMessage, setPerson9NameErrorMessage] = useState("");

    const [person10Name, setPerson10Name] = useState("");
    const [isPerson10NameError, setIsPerson10NameError] = useState(false);
    const [person10NameErrorMessage, setPerson10NameErrorMessage] = useState("");

    const handleFileUpload = (e) => {
        e.preventDefault();
        const file_data = e.target.files[0];
        setIsFileUploaded(true);
        setFile(file_data);
    }

    const uploadParticipantsExcel = (e) => {
        e.preventDefault();

        let loadingToast = toast.loading("Inserting Participants...", {
            position: "bottom-center",
            duration: 5000
        });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': user.access_token
            }
        }

        var formData = new FormData();
        formData.append(`file`, file);

        axios.post(`${REACT_APP_API_BASE_URL}participant/add-participants/${user.id}`, formData, config, {
            onUploadProgress: (ProgressEvent) => {
                let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            }
        }).then(res => {
            const status = res.status;
            if (status === 200) {
                toast.dismiss(loadingToast);
                ToastAlert.notifySuccess("Successfully Updated!");
                participantsAdded({
                    status: true
                });
            }
            else{
                toast.dismiss(loadingToast);
                ToastAlert.notifyError("Failed To Update. Try Again Later!");
            }
        }).catch(err => {
            toast.dismiss(loadingToast);
            ToastAlert.notifyError("Failed To Update. Try Again Later!");
        });
    }
    
    const submitAddParticipantsManually = async (e) => {
        e.preventDefault();

        let loadingToast = toast.loading("Inserting Participants...", {
            position: "bottom-center",
            duration: 5000
        });

        let isError = false;

        const users = [];

        if(person1Name !== ""){
            if(person1Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person1Name
                }
    
                users.push(data);
            }
        }

        if(person2Name !== ""){
            if(person2Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person2Name
                }
    
                users.push(data);
            }
        }

        if(person3Name !== ""){
            if(person3Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person3Name
                }
    
                users.push(data);
            }
        }

        if(person4Name !== ""){
            if(person4Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person4Name
                }
    
                users.push(data);
            }
        }

        if(person5Name !== ""){
            if(person5Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person5Name
                }
    
                users.push(data);
            }
        }

        if(person6Name !== ""){
            if(person6Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person6Name
                }
    
                users.push(data);
            }
        }

        if(person7Name !== ""){
            if(person7Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person7Name
                }
    
                users.push(data);
            }
        }

        if(person8Name !== ""){
            if(person8Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person8Name
                }
    
                users.push(data);
            }
        }

        if(person9Name !== ""){
            if(person9Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person9Name
                }
    
                users.push(data);
            }
        }

        if(person10Name !== ""){
            if(person10Name.replaceAll(/\s/g,'') !== ""){
                let data = {
                    name: person10Name
                }
    
                users.push(data);
            }
        }

        else if(users.length === 0){
            ToastAlert.notifyError("Atleast 1 Participant is Required!");
            return
        }
        else{

            const config = {
                headers: {
                    'Authorization': user.access_token
                }
            }

            const data = {
                participants: JSON.stringify(users)
            }

            try{
                const saveParticipantsReq = await axios.post(`${REACT_APP_API_BASE_URL}participant/manual/${user.id}`, data, config);
                if (saveParticipantsReq.status === 200) {
                    toast.dismiss(loadingToast);
                    ToastAlert.notifySuccess("Successfully Updated!");
                    participantsAdded({
                        status: true
                    });
                }
                else{
                    toast.dismiss(loadingToast);
                    ToastAlert.notifyError("Failed To Update. Try Again Later!");
                }
            }
            catch(error){
                toast.dismiss(loadingToast);
                ToastAlert.notifyError("Failed To Update. Try Again Later!");
            }
        }
    }
    

    return (
        <div className="addParticpantsContainer">
            <div className="addParticipantsRadios">
                <div className="adminDashboardPageContentFilters">
                    <div className="adminDashboardPageContentFilterStep">
                        <label className="form-control">
                            <input type="radio" name="participant_type" defaultChecked={uploadType === "excel"} onChange={(e) => setUploadType("excel")} />
                            Upload Excel
                        </label>
                        <label className="form-control">
                            <input type="radio" name="participant_type" defaultChecked={uploadType === "manual"} onChange={(e) => setUploadType("manual")} />
                            Enter Manually
                        </label>
                    </div>
                    {
                        uploadType === "excel"
                        ?
                            <form onSubmit={(e) => uploadParticipantsExcel(e)}>
                                <div className="adminDashboardPageContentFilterStep">
                                    <div className="pageContentSectionFormStepHalf">
                                        <div className="pageContentSectionFormStepLable">
                                            <p className="pageContentSectionFormStepHalfLableTxt">Excel Spreadsheet</p>
                                        </div>
                                        <div className="pageContentSectionFormStepInput">
                                            <input type="file" className="pageContentSectionFormStepInputTxt" placeholder="Select Spreadsheet to Upload" onChange={(e) => handleFileUpload(e)} accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' />
                                        </div>
                                    </div>
                                    <div className="pageContentSectionFormStepHalf">
                                        <div className="pageContentSectionFormStepLable">
                                            <p className="pageContentSectionFormStepHalfLableTxt">&nbsp;</p>
                                        </div>
                                        <div className="pageContentSectionFormStepInput submitEnd">
                                            <div className="pageContentSectionFormStepInputInner">
                                                <button className="adminDashboardPageContentFilterActionSingleButtonInput cancel" type={'button'} onClick={() => participantsAdded({status: false})}>cancel</button>
                                                <button className="adminDashboardPageContentFilterActionSingleButtonInput" type={'submit'}>submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        :
                        uploadType === "manual"
                        ?
                            <>
                                <div className="addParticipantsManually">
                                    <div className="addParticipantsManuallyHeading">
                                        <p className="addParticipantsManuallyHeadingTxt">Fill The Below Form To Add Participants Manually</p>
                                        <p className="addParticipantsManuallySubHeadingTxt">You can add upto 10 participants at a time.</p>
                                    </div>

                                    <form onSubmit={(e) => submitAddParticipantsManually(e)} noValidate>
                                        <div className="addParticipantsManuallyForm">
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 1</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person1Name} onChange={(e) => setPerson1Name(e.target.value)}  placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson1NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person1NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 2</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person2Name} onChange={(e) => setPerson2Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson2NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person2NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 3</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person3Name} onChange={(e) => setPerson3Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson3NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person3NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 4</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person4Name} onChange={(e) => setPerson4Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson4NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person4NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 5</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person5Name} onChange={(e) => setPerson5Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson5NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person5NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 6</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person6Name} onChange={(e) => setPerson6Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson6NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person6NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 7</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person7Name} onChange={(e) => setPerson7Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson7NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person7NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 8</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person8Name} onChange={(e) => setPerson8Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson8NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person8NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 9</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person9Name} onChange={(e) => setPerson9Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson9NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person9NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addParticipantsManuallyFormStep">
                                                <div className="addParticipantsManuallyFormStepHeading">
                                                    <p className="addParticipantsManuallyFormStepHeadingTxt">Person 10</p>
                                                </div>
                                                <div className="addParticipantsManuallyFormStepData">
                                                    <div className="addParticipantsManuallyFormStepHalf">
                                                        <div className="addParticipantsManuallyFormStepLable">
                                                            <p className="addParticipantsManuallyFormStepLableTxt"> name</p>
                                                        </div>
                                                        <div className="addParticipantsManuallyFormStepInput">
                                                            <input type="text" className="addParticipantsManuallyFormStepInputTxt" value={person10Name} onChange={(e) => setPerson10Name(e.target.value)} placeholder="Enter participant name" maxLength={REACT_APP_SMALL_NAME_LENGTH} />
                                                        </div>
                                                        {
                                                            isPerson10NameError
                                                            ?
                                                            <div className="addParticipantsManuallyFormStepError">
                                                                <p className="addParticipantsManuallyFormStepErrorTxt">{person10NameErrorMessage}</p>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="adminDashboardPageContentFilterStep">
                                                <div className="pageContentSectionFormStepHalf"></div>
                                                <div className="pageContentSectionFormStepHalf">
                                                    <div className="pageContentSectionFormStepInput submitEnd">
                                                        <button className="adminDashboardPageContentFilterActionSingleButtonInput" type={'submit'}>submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        :
                            <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddParticipants