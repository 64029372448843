import React from 'react'

const ThankYouAnywaysPopUp = ({ concludePopUp }) => {
    return (
        <div className="thankYouAnywayPopUpWithBg">
            <div className="thankYouAnywayPopUp">
                <div className="thankYouAnywayPopUpHeading">
                    <p className="thankYouAnywayPopUpHeadingTxt">Thank you!</p>
                </div>
                <div className="thankYouAnywayPopUpContent">
                    <div className="thankYouAnywayPopUpContentInner">
                        <div className="thankYouAnywayPopUpContentDescription">
                            <p className="thankYouAnywayPopUpContentDescriptionTxt">Anyway, thanks for participating in the event and visiting the website.</p>
                            {/* <p className="thankYouAnywayPopUpContentDescriptionTxt">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                        </div>
                        <div className="thankYouAnywayPopUpContentActions">
                            <div className="thankYouAnywayPopUpContentActionsInner">
                                <button className="thankYouAnywayPopUpContentAction agree" type="button" onClick={() => concludePopUp(true)}>Confirm</button>
                                <button className="thankYouAnywayPopUpContentAction cancel" type="button" onClick={() => concludePopUp(false)}>Go Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYouAnywaysPopUp