import axios from "axios";
import { statusCodes, defaults } from "../../utils";

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const checkUserAvailability = async (payload) => {
    if(!payload.identity){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Email ID / Phone Number is required.",
        });

        return response
    }

    const data = {
        identity: payload.identity,
        type: payload.type
    }

    try{
        const loginUserReq = await axios.post(`${defaults.API_BASE_URL}user/login`, data);
        if(loginUserReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "Registrar with the given Email ID not found.",
            });
    
            return response
        }
        else if(loginUserReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "User found.",
                data: loginUserReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "User login request failed."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "User login request failed."
        });

        return response
    }
}

const validateOTP = async (payload) => {
    if(!payload.user){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "User is required.",
        });

        return response
    }
    if(!payload.otp){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "OTP is required.",
        });

        return response
    }

    const data = {
        user: payload.user,
        otp: payload.otp
    }

    try{
        const validateOTPReq = await axios.post(`${defaults.API_BASE_URL}user/validate/otp`, data);
        if(validateOTPReq.status === statusCodes.NOT_FOUND){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.NOT_FOUND,
                message: "User not found.",
            });
    
            return response
        }
        else if(validateOTPReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "User Logged In Successfully!",
                data: validateOTPReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "User login request failed."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please verify your OTP.",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Something went wrong!"
            });
    
            return response
        }
    }
}

const UserServices = {
    checkUserAvailability,
    validateOTP
}

export default UserServices