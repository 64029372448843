import React, { useEffect, useState } from 'react'
import SelfDeclaration from '../../components/SelfDeclaration';
import DownloadInstitutionCertificate from '../../components/DownloadInstitutionCertificate';
import SelectCertificateLanguage from '../../components/SelectCertificateLanguage';

const InstituteDashboardPage = ({ user }) => {
    return (
        <>
            {
                user.is_self_declaration_signed === false
                ?
                    <>
                        <SelfDeclaration user={user} />
                    </>
                :
                user.certificate_language === "NOT_SET"
                ?
                    <>
                        <SelectCertificateLanguage user={user} />
                    </>
                :
                user.is_self_declaration_signed === true && user.certificate_language !== "NOT_SET"
                ?
                    <DownloadInstitutionCertificate user={user} />
                :
                    <></>
            }
        </>
    )
}

export default InstituteDashboardPage