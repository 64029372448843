import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, allowOTPValidation, logoutUser } from '../../reducers/User';
import { ToastAlert, defaults, statusCodes, validations } from '../../utils';
import { UserServices } from '../../services';
import PreLoginChooseCertificateType from '../../components/PreLoginChooseCertificateType';
import PreLoginPreviewCertificates from '../../components/PreLoginPreviewCertificates';
import PreLoginVisualForm from '../../components/PreLoginVisualForm';
import ThankYouAnywaysPopUp from '../../components/ThankYouAnywaysPopUp';

const HomePage = ({ user }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser());
    }, []);

    return (
        <>
            {
                user.is_logged_in === false
                ?
                <div className="preLoginPageContentMain">
                    <div className="preLoginPageContentInner">
                        {
                            user.is_claim_certificate === true && user.certificate_type === null
                            ?
                                <PreLoginChooseCertificateType user={user} />
                            :
                                <></>
                        }
                        {
                            user.certificate_type === null
                            ?
                                <PreLoginPreviewCertificates />
                            :
                                <PreLoginVisualForm user={user} />
                        }
                    </div>
                </div>
                :
                <></>
            }
        </>
    )
}

export default HomePage