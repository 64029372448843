import React from 'react'
import SelectCertificateLanguage from '../../components/SelectCertificateLanguage';
import ManageParticipants from '../../components/ManageParticipants';
import SelfDeclarationIndividual from '../../components/SelfDeclarationIndividual';

const IndividualDashboardPage = ({ user }) => {
    return (
        <>
            {
                user.is_self_declaration_signed === false
                ?
                    <SelfDeclarationIndividual user={user} />
                :
                user.certificate_language === "NOT_SET"
                ?
                    <SelectCertificateLanguage user={user} />
                :
                user.is_self_declaration_signed === true && user.certificate_language !== "NOT_SET"
                ?
                    <ManageParticipants user={user} />
                :
                    <></>
            }
        </>
    )
}

export default IndividualDashboardPage