import React from 'react'

const PreLoginPublicHeader = () => {
    return (
        <div className="preLoginPageHeaderContainer">
            <div className="preLoginPageHeaderInner">
                <div className="preLoginPageHeaderEmblemPeople">
                    <div className="preLoginPageHeaderEmblem">
                        <div className="preLoginPageHeaderEmblemSingle">
                            <div className="preLoginPageHeaderEmblemSingleImage">
                                <img src="/images/state-emblem-header.jpg" className="preLoginPageHeaderEmblemSingleImg" alt="Govt. of Karnataka" />
                            </div>
                        </div>
                        <div className="preLoginPageHeaderEmblemSingle">
                            <div className="preLoginPageHeaderEmblemSingleImage">
                                <img src="/images/ambedkar-header.jpg" className="preLoginPageHeaderEmblemSingleImg" alt="B.R. Ambedkar" />
                            </div>
                        </div>
                    </div>

                    <div className="preLoginPageHeaderPeople">
                        <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-01.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-02.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-03.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        {/* <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-04.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div> */}
                        {/* <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-05.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="preLoginPageHeaderPeopleSingle">
                            <div className="preLoginPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-06.jpg" className="preLoginPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="preLoginPageHeaderContent">
                    <div className="preLoginPageHeaderContentStep">
                        <div className="preLoginPageHeaderContentWithBg">
                            <p className="preLoginPageHeaderContentDescriptionTxt">Thank you for taking part in </p>
                        </div>
                    </div>
                    <div className="preLoginPageHeaderContentStep noMargin">
                        <p className="preLoginPageHeaderContentStepHeadingTxt">The Constitution & National Unity Convention'24</p>
                    </div>
                    {/* <div className="preLoginPageHeaderContentStep">
                        <p className="preLoginPageHeaderContentStepTaglineTxt"></p>
                    </div> */}
                    {/* <div className="preLoginPageHeaderContentStep">
                        <div className="preLoginPageHeaderContentWithBg">
                            <p className="preLoginPageHeaderContentDescriptionTxt">as a part of celebrating Constitution Day AKA National Law Day!</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default PreLoginPublicHeader