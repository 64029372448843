import React from 'react';
import { Outlet } from 'react-router-dom';
import PublicDashboardLayout from './PublicDashboardLayout';

const PublicDashboardOutlet = ({ user }) => {
    return (
        <>
            <PublicDashboardLayout user={user}>
                <Outlet />
            </PublicDashboardLayout>
        </>
    );
};

export default PublicDashboardOutlet;