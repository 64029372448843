import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    id: "", 
    reg_id: "", 
    org_name: "", 
    org_code: "", 
    org_type: "", 
    org_of: "", 
    person_name: "", 
    email_id: "", 
    mobile_no: "", 
    no_of_participants: "", 
    id_card_url: "",
    address: "", 
    country: "", 
    state: "", 
    district: "", 
    taluk: "", 
    city: "", 
    locality: "", 
    kyc_id_type: "", 
    kyc_id_number: "", 
    nationality_type: "", 
    registration_type: "", 
    access_token: "", 
    refresh_token: "", 
    certificate_language: "NOT_SET",
    is_claim_certificate: null,
    certificate_type: null,
    is_authorized: null,
    is_details_confirmed: false, 
    is_logged_in: false, 
    allow_otp_validation: false,
    is_self_declaration_signed: false,
    has_new_data: false
}

export const userSlice = createSlice({
    name: "user",
    initialState: {
        value: initialValue
    },
    reducers: {
        allowOTPValidation: (state, action) => {
            state.value.id = action.payload.id;
            state.value.email_id = action.payload.email_id;
            state.value.mobile_no = action.payload.mobile_no;
            state.value.allow_otp_validation = true;
        },
        markGiveUpCertificate: (state, action) => {
            state.value.is_claim_certificate = false;
        },
        markClaimCertificate: (state, action) => {
            state.value.is_claim_certificate = true;
        },
        markCertificateType: (state, action) => {
            state.value.certificate_type = action.payload.type;
        },
        updateIDCardURL: (state, action) => {
            state.value.id_card_url = action.payload.id_card_url;
        },
        markConfirmed: (state, action) => {
            state.value.is_details_confirmed = true;
        },
        markHasNewData: (state, action) => {
            state.value.has_new_data = true;
        },
        markHasNoNewData: (state, action) => {
            state.value.has_new_data = false;
        },
        markSelfDeclarationSigned: (state, action) => {
            state.value.is_self_declaration_signed = true;
        },
        updateLanguage: (state, action) => {
            state.value.certificate_language = action.payload.language;
        },
        updateOrganizationName: (state, action) => {
            state.value.org_name = action.payload.name;
        },
        loginUser: (state, action) => {
            state.value = action.payload;
        },
        logoutUser: (state) => {
            state.value = initialValue;
        }
    }
});

export const { allowOTPValidation, markGiveUpCertificate, markClaimCertificate, markCertificateType, updateIDCardURL, markConfirmed, markHasNewData, markHasNoNewData, markSelfDeclarationSigned, updateLanguage, updateOrganizationName, loginUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;