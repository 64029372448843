import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from "../../config"

const PrintInstitutePDFPage = ({ user }) => {
    const params = useParams();

    console.log(params);

    const [institute, setInstitute] = useState(null);

    const generateCertificateHandler = async () => {
        try{
            const getPreviewCertificateReq = await axios.get(`${REACT_APP_API_BASE_URL}user/institute/${params.id}`);
            if(getPreviewCertificateReq.status === 200){
                console.log(getPreviewCertificateReq.data.data);
                setInstitute(getPreviewCertificateReq.data.data);
            }
            else{
                console.log("error");
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        generateCertificateHandler();
    }, []);

    useEffect(() => {
        console.log(institute);
    }, [institute]);

    return (
        <div className="certificatePage">
            <div className="certificatePageMain">
                <div className="certificateInstitutePageName">
                    {
                        institute?.certificate_language === "KANNADA" 
                        ?
                            <div className="certificateInstitutePageNameKannada">
                                <p className="certificateInstitutePageNameKannadaTxt" style={{
                                    fontSize: institute?.name.length < 15 ? "2.5em" : "2em"
                                }}>{institute?.name}</p>
                            </div>
                        :
                        institute?.certificate_language === "ENGLISH" 
                        ?
                            <div className="certificateInstitutePageNameEnglish">
                                <p className="certificateInstitutePageNameEnglishTxt" style={{
                                    fontSize: institute?.name.length < 20 ? "2.5em" : "2em"
                                }}>{institute?.name}</p>
                            </div>
                        :
                            <div className="certificateInstitutePageNameEnglish">
                                <p className="certificateInstitutePageNameEnglishTxt" style={{
                                    fontSize: institute?.name.length < 20 ? "2.5em" : "2em"
                                }}>{institute?.name}</p>
                            </div>
                    }
                </div>

                <div className="certificateInstitutePageQrCode">
                    <div className="certificateInstitutePageQrCodeImage">
                        <img src={institute?.qr_code} className="certificateInstitutePageQrCodeImg" />
                    </div>
                </div>
                <div className="certificateInstitutePageImage">
                    <img src="/images/certificate-print.webp" className="certificateInstitutePageImg" />
                </div>
            </div>
        </div>
    )
}

export default PrintInstitutePDFPage