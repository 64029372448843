import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { markClaimCertificate, markGiveUpCertificate } from '../reducers/User';
import ThankYouAnywaysPopUp from './ThankYouAnywaysPopUp';

const PreLoginPublicBanner = ({ user }) => {
    const dispatch = useDispatch();

    const [showThanksAnywaysPopUp, setShowThanksAnywaysPopUp] = useState(false);

    const handleClaimCertificate = async () => {
        dispatch(markClaimCertificate());
        window.scrollTo({
            top: 600,
            behavior: 'smooth',
        })
    }

    const handleThanksAnyways = async () => {
        setShowThanksAnywaysPopUp(true);
    }

    const handleConcludeThanksAnywaysPopUp = async (e) => {
        if(e === true){
            dispatch(markGiveUpCertificate());
            setShowThanksAnywaysPopUp(false);
        }
        else{
            setShowThanksAnywaysPopUp(false);
        }
    }

    return (
        <>
            <div className="preLoginPageContentBanner">
                <div className="preLoginPageContentBannerLable">
                    <p className="preLoginPageContentBannerLableTxt">Do you want to claim certificate?</p>
                </div>
                <div className="preLoginPageContentBannerAction">
                    <button className="preLoginPageContentBannerActionSingle success" onClick={handleClaimCertificate}>yes</button>
                    <button className="preLoginPageContentBannerActionSingle error" onClick={handleThanksAnyways}>no</button>
                </div>
            </div>

            {
                showThanksAnywaysPopUp === true
                ?
                    <ThankYouAnywaysPopUp concludePopUp={(e) => handleConcludeThanksAnywaysPopUp(e)} />
                :
                    <></>
            }
        </>
    )
}

export default PreLoginPublicBanner