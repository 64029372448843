import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    id: "", 
    name: "", 
    email_id: "", 
    access_token: "", 
    refresh_token: "", 
    is_logged_in: false
}

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        value: initialValue
    },
    reducers: {
        loginAdmin: (state, action) => {
            state.value = action.payload;
        },
        logoutAdmin: (state) => {
            state.value = initialValue;
        },
    }
});

export const { loginAdmin, logoutAdmin } = adminSlice.actions;

export default adminSlice.reducer;