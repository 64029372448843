import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer'
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from '../../config';

const ValidateInstitutionCertificatePage = () => {
    const params = useParams();

    const [user, setUser] = useState(null);

    const [userError, setUserError] = useState(false);

    const getThisUser = async () => {
        try{
            const getUserReq = await axios.get(`${REACT_APP_API_BASE_URL}participant/validate/institute/${params.id}`);
            // const getUserReq = await axios.get(`${"http://localhost:4040/api/"}participant/validate/institute/${params.id}`);
            if(getUserReq.status === 200){
                setUser(getUserReq.data.data);
                setUserError(false);
            }
            else{
                setUser(null);
                setUserError(false);
            }
        }
        catch(error) {
            if(error.response.status === 500){
                setUserError(true);
                setUser(null);
            }
            else{
                setUserError(true);
                setUser(null);
            }
        }
    }

    useEffect(() => {
        getThisUser();
    }, []);

    return (
        <>
            <div className="validatePage">
                <div className="validatePageHeader">
                    <div className="validatePageHeaderInner">
                        <div className="validatePageHeaderEmblem">
                            <div className="validatePageHeaderEmblemInner">
                                <div className="validatePageHeaderEmblemSingle">
                                    <div className="validatePageHeaderEmblemSingleImage">
                                        <img src="/images/state-emblem-header.jpg" className="validatePageHeaderEmblemSingleImg" alt="Govt. of Karnataka" />
                                    </div>
                                </div>
                                <div className="validatePageHeaderEmblemSingle">
                                    <div className="validatePageHeaderEmblemSingleImage">
                                        <img src="/images/ambedkar-header.jpg" className="validatePageHeaderEmblemSingleImg" alt="B.R. Ambedkar" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="validatePageHeaderContent">
                            <div className="validatePageHeaderContentStep">
                                <p className="validatePageHeaderContentHeadingTxt">The Preamble</p>
                            </div>
                            <div className="validatePageHeaderContentStep">
                                <p className="validatePageHeaderContentStepTaglineTxt">of the Constitution of India</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="validatePageContent">
                    <div className="validatePageContentInner">
                        {
                            user != null && userError == false
                            ?
                            <div className="validatePageContentMain">
                                <div className="validatePageContentIcon">
                                    <div className="validatePageContentIconInner">
                                        <i className="fa-solid fa-circle-check validatePageContentIco"></i>
                                    </div>
                                </div>
                                <div className="validatePageContentHeading">
                                    <p className="validatePageContentHeadingTxt">Your Certificate Is Authentic!</p>
                                </div>
                                <div className="validatePageContentDescription">
                                    <p className="validatePageContentDescriptionTxt">The scanned certificate was issued to <span className="name">{user?.organization_name}</span> for taking part in reading the preamble of the constitution of India on 15th September 2023.</p>
                                </div>
                            </div>
                            :
                            user == null && userError == true
                            ?
                            <div className="validatePageContentMain">
                                <div className="validatePageContentIcon">
                                    <div className="validatePageContentIconInner">
                                        <i class="fa-solid fa-circle-xmark inValidatePageContentIco"></i>
                                    </div>
                                </div>
                                <div className="validatePageContentHeading">
                                    <p className="validatePageContentHeadingTxt">Your Certificate Is Invalid!</p>
                                </div>
                                <div className="validatePageContentDescription">
                                    <p className="validatePageContentDescriptionTxt">The scanned certificate was not found on our records. Please scan again or reverify the certificate.</p>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
        
    )
}

export default ValidateInstitutionCertificatePage