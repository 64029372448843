export const REACT_APP_CMS4_OWNER_AUTHOR="CEG, Govt. of Karnataka."
export const REACT_APP_CMS_VERSION="v1"
// export const REACT_APP_API_BASE_URL="http://localhost:4040/api/"
// export const REACT_APP_BASE_URL="http://localhost:4040/api/"
export const REACT_APP_API_BASE_URL="https://crt.constitutionofindia-karnataka.in/api/"
export const REACT_APP_BASE_URL="https://crt.constitutionofindia-karnataka.in/api/"
export const REACT_APP_JWT_SECRET="sec!15!SwdCeg"
export const REACT_APP_FIRST_PAGE=1
export const REACT_APP_DEFAULT_PAGE_SIZE=10
export const REACT_APP_DEFAULT_NAME_SIZE="1.5em"
export const REACT_APP_SMALL_NAME_SIZE="1.2em"
export const REACT_APP_DEFAULT_NAME_LENGTH=20
export const REACT_APP_SMALL_NAME_LENGTH=35