import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, allowOTPValidation, logoutUser } from '../reducers/User';
import { ToastAlert, defaults, statusCodes, validations } from '../utils';
import { UserServices } from '../services';

const PreLoginVisualForm = ({ user }) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [hasEmailMessage, setHasEmailMessage] = useState(false);
    const [emailMessage, setEmailMessage] = useState("");
    const [emailMessageType, setEmailMessageType] = useState("");

    const [otp, setOTP] = useState("");
    const [hasOTPMessage, setHasOTPMessage] = useState(false);
    const [otpMessage, setOTPMessage] = useState("");
    const [otpMessageType, setOTPMessageType] = useState("");
    const [disableResendOTP, setDisableResendOTP] = useState(false)

    const [showOTPValidation, setShowOTPValidation] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(user.allow_otp_validation === true){
            setShowOTPValidation(true);
        }
        else{
            setShowOTPValidation(false);
        }
    }, [user]);

    const checkUserExistance = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const isEmailValid = await validations.validateEmailID(email);
        const isPhoneValid = await validations.validatePhoneNumber(email);

        if(isEmailValid.status === false && isPhoneValid === false){
            setHasEmailMessage(true);
            setEmailMessage("Enter a Valid Email ID / Phone No.");
            setEmailMessageType("error");

            setIsLoading(false);
            return
        }
        else{
            setHasEmailMessage(false);
            setEmailMessage("");
            setEmailMessageType("");
        }

        const loginUserReq = await UserServices.checkUserAvailability({
            identity: email,
            type: user.certificate_type
        });
        if(loginUserReq.status === false){
            ToastAlert.notifyError(loginUserReq.message);
            setIsLoading(false);
        }
        else{
            if(user.certificate_type === "individual"){
                if(loginUserReq.data.user_type === "NRI" || loginUserReq.data.user_type === "INDIVIDUAL"){
                    setIsLoading(false);
                    dispatch(
                        allowOTPValidation(
                            {
                                id: loginUserReq.data.user_id,
                                email_id: loginUserReq.data.email_id,
                                mobile_no: loginUserReq.data.mobile_no,
                                allow_otp_validation: true
                            }
                        )
                    )
                }
                else{
                    ToastAlert.notifyError("User not found with this Email ID");
                    setIsLoading(false);
                }
            }
            else if(user.certificate_type === "institute"){
                if(loginUserReq.data.user_type !== "NRI" && loginUserReq.data.user_type !== "INDIVIDUAL"){
                    setIsLoading(false);
                    dispatch(
                        allowOTPValidation(
                            {
                                id: loginUserReq.data.user_id,
                                email_id: loginUserReq.data.email_id,
                                mobile_no: loginUserReq.data.mobile_no,
                                allow_otp_validation: true
                            }
                        )
                    )
                }
                else{
                    ToastAlert.notifyError("Institute not found with this Email ID");
                    setIsLoading(false);
                }
            }
            else if(user.certificate_type === "institute_individual"){
                if(loginUserReq.data.user_type !== "NRI" && loginUserReq.data.user_type !== "INDIVIDUAL"){
                    setIsLoading(false);
                    dispatch(
                        allowOTPValidation(
                            {
                                id: loginUserReq.data.user_id,
                                email_id: loginUserReq.data.email_id,
                                mobile_no: loginUserReq.data.mobile_no,
                                allow_otp_validation: true
                            }
                        )
                    )
                }
                else{
                    ToastAlert.notifyError("Participant not found with this Email ID");
                    setIsLoading(false);
                }
            }
            else{
                ToastAlert.notifyError("Something went wrong!");
                setIsLoading(false);
            }
        }
    }

    const handleOTPChange = (data, max_length) => {
        const isOtpNumberValid = validations.allowOnlyNumbers(data);

        if(isOtpNumberValid){
            if(otp.length >= max_length){
                if(data.length >= max_length){
                    return
                }
            }
            setOTP(data);
            setHasOTPMessage(false);
            setOTPMessage('');
        }
        else if(data.length === 0){
            setOTP('');
            setHasOTPMessage(true);
            setOTPMessage('OTP is Required');
        }
    }

    const checkUserOTP = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        if(otp.length !== 6){
            setHasOTPMessage(true);
            setOTPMessage('Invalid OTP');
        }
        else{
            setHasOTPMessage(false);
            setOTPMessage('');

            const data = {
                user: user.id,
                otp: otp
            }

            const validateOTPReq = await UserServices.validateOTP(data);
            if(validateOTPReq.status === false){
                ToastAlert.notifyError(validateOTPReq.message);
                setIsLoading(false);
            }
            else{
                setIsLoading(false);
                dispatch(
                    loginUser({
                        id: validateOTPReq.data.user.id,
                        reg_id: validateOTPReq.data.user.registration_id,
                        org_name: validateOTPReq.data.user?.organization_name,
                        org_code: validateOTPReq.data.user?.organization_code,
                        org_type: validateOTPReq.data.user?.organization_type,
                        org_of: validateOTPReq.data.user?.organization_of,
                        person_name: validateOTPReq.data.user?.person_name,
                        email_id: validateOTPReq.data.user?.email_id,
                        mobile_no: validateOTPReq.data.user?.mobile_number,
                        no_of_participants: validateOTPReq.data.user?.no_of_participants,
                        id_card_url: validateOTPReq.data.user?.id_card_url,
                        address: validateOTPReq.data.user?.address,
                        country: validateOTPReq.data.user?.country,
                        state: validateOTPReq.data.user?.state,
                        district: validateOTPReq.data.user?.district,
                        taluk: validateOTPReq.data.user?.taluk,
                        city: validateOTPReq.data.user?.city,
                        locality: validateOTPReq.data.user?.locality,
                        kyc_id_type: validateOTPReq.data.user?.kyc_id_type,
                        kyc_id_number: validateOTPReq.data.user?.kyc_id_number,
                        nationality_type: validateOTPReq.data.user?.nationality_type,
                        registration_type: validateOTPReq.data.user?.registration_type,
                        access_token: validateOTPReq.data.access_token,
                        refresh_token: validateOTPReq.data.refresh_token,
                        is_logged_in: true,
                        allow_otp_validation: false,
                        is_details_confirmed: false,
                        certificate_type: user.certificate_type,
                        is_authorized: validateOTPReq.data.user?.id_card_url,
                        is_self_declaration_signed: validateOTPReq.data.user?.is_self_declaration_signed,
                        certificate_language: validateOTPReq.data.user?.certificate_language
                    })
                )
            }
        }
    }

    const logout = () => {
        dispatch(logoutUser());
    }

    return (
        <div className="preLoginPageContentVisualForm">
            <div className="preLoginPageContentVisual">
                <div className="preLoginPageContentVisualImage">
                    <img src="/images/preamble.webp" className="preLoginPageContentVisualImg" alt="The Preamble" />
                </div>
            </div>

            <div className="preLoginPageContentInfoDesignedBy">
                <div className="preLoginPageContentInfo">
                    <div className="preLoginPageContentInfoInner">
                        <div className="preLoginPageContentInfoHeading">
                            <p className="preLoginPageContentInfoHeadingTxt">Sign in With Your Registered Email ID<br /> Or Phone Number To Generate Certificates!</p>
                        </div>
                        <div className="preLoginPageContentInfoForm">
                            {
                                showOTPValidation === false
                                ?
                                <>
                                    <form className="preLoginPageContentInfoFormMain" onSubmit={(e) => checkUserExistance(e)} noValidate>
                                        <div className="preLoginPageContentInfoFormInputs">
                                            <div className="preLoginPageContentInfoFormInputStep">
                                                <div className="preLoginPageContentInfoFormInputStepLable">
                                                    <p className="preLoginPageContentInfoFormInputStepLableTxt">Email ID / Phone Number *</p>
                                                </div>
                                                <div className="preLoginPageContentInfoFormInputStepInput">
                                                    <input type="email" className="preLoginPageContentInfoFormInputStepInputTxt" placeholder="Enter your Email ID / Phone Number" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                {
                                                    hasEmailMessage
                                                    ?
                                                    <div className="preLoginPageContentInfoFormInputStepError">
                                                        <p className="preLoginPageContentInfoFormInputStepErrorTxt">{emailMessage}</p>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="preLoginPageContentInfoFormActions">
                                            <button type="submit" className="preLoginPageContentInfoFormAction">submit</button>
                                        </div>
                                    </form>
                                    <div className="preLoginPageContentInfoResendReEnter noMargin">
                                        <div className="preLoginPageContentInfoReEnter">
                                            <div className="preLoginPageContentInfoReEnterLable">
                                                <p className="preLoginPageContentInfoReEnterLableTxt">Looking to Change Certificate Type?</p>
                                            </div>
                                            <div className="preLoginPageContentInfoReEnterAction">
                                                <p className="preLoginPageContentInfoReEnterActionTxt" onClick={logout}>GO BACK</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <form className="preLoginPageContentInfoFormMain" onSubmit={(e) => checkUserOTP(e)}>
                                        <div className="preLoginPageContentInfoFormInputs">
                                            <div className="preLoginPageContentInfoFormInputStep">
                                                <div className="preLoginPageContentInfoFormInputStepLable">
                                                    <p className="preLoginPageContentInfoFormInputStepLableTxt">OTP *</p>
                                                </div>
                                                <div className="preLoginPageContentInfoFormInputStepInput">
                                                    <input type="tel" className="preLoginPageContentInfoFormInputStepInputTxt" placeholder="Enter your OTP" value={otp} onChange={(e) => handleOTPChange(e.target.value, 6)} />
                                                </div>
                                                {
                                                    hasOTPMessage
                                                    ?
                                                    <div className="preLoginPageContentInfoFormInputStepError">
                                                        <p className="preLoginPageContentInfoFormInputStepErrorTxt">{otpMessage}</p>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="preLoginPageContentInfoFormActions">
                                            <button type="submit" className="preLoginPageContentInfoFormAction">submit</button>
                                        </div>
                                    </form>

                                    <div className="preLoginPageContentInfoResendReEnter">
                                        <div className="preLoginPageContentInfoResend">
                                            <div className="preLoginPageContentInfoResendLable">
                                                <p className="preLoginPageContentInfoResendLableTxt">Haven't received OTP?</p>
                                            </div>
                                            <div className="preLoginPageContentInfoResendAction">
                                                <p className={`preLoginPageContentInfoResendActionTxt ${disableResendOTP ? 'disabled' : ''}`}>RESEND</p>
                                            </div>
                                        </div>
                                        <div className="preLoginPageContentInfoReEnter">
                                            <div className="preLoginPageContentInfoReEnterLable">
                                                <p className="preLoginPageContentInfoReEnterLableTxt">Re-enter Email ID / Phone Number?</p>
                                            </div>
                                            <div className="preLoginPageContentInfoReEnterAction">
                                                <p className="preLoginPageContentInfoReEnterActionTxt" onClick={logout}>RE-ENTER</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="preLoginPageContentInfoContact">
                        <div className="preLoginPageContentInfoContactInner">
                            <p className="preLoginPageContentInfoContactLable">Facing Trouble in Sign In?</p>
                            <p className="preLoginPageContentInfoContactValue">Reach us at: +91 94823 00400</p>
                        </div>
                    </div>
                </div>
                <div className="preLoginPageContentDesignedBy">
                    <div className="preLoginPageContentDesignedByInner">
                        <div className="preLoginPageContentDesignedByLable">
                            <p className="preLoginPageContentDesignedByLableTxt">designed &amp; developed by:</p>
                        </div>
                        <a href="https://ceg.karnataka.gov.in" target="_blank" rel="noreferrer" className="preLoginPageContentDesignedByImage">
                            <img src="/images/ceg.png" className="preLoginPageContentDesignedByImg" alt="CEG" />
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default PreLoginVisualForm