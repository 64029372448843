import React from 'react'

const PreLoginPreviewCertificates = () => {
    return (
        <div className="preLoginPageContentSection">
            <div className="preLoginPageContentSectionHeading">
                <p className="preLoginPageContentSectionHeadingTxt">Preview Certificates</p>
            </div>

            <div className="preLoginPageContentSectionContent">
                <div className="preLoginPageContentSectionContentCertificates">
                    <div className="preLoginPageContentSectionContentCertificateSingle">
                        <div className="preLoginPageContentSectionContentCertificateSingleImage">
                            <img src="/images/institution-certificate-preview.webp" className="preLoginPageContentSectionContentCertificateSingleImg" />
                        </div>
                    </div>
                    <div className="preLoginPageContentSectionContentCertificateSingle">
                        <div className="preLoginPageContentSectionContentCertificateSingleImage">
                            <img src="/images/individual-certificate-preview.webp" className="preLoginPageContentSectionContentCertificateSingleImg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLoginPreviewCertificates