import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { logoutUser, markHasNewData, markHasNoNewData, markSelfDeclarationSigned, updateOrganizationName } from '../reducers/User';
import { REACT_APP_API_BASE_URL, REACT_APP_DEFAULT_NAME_SIZE, REACT_APP_SMALL_NAME_SIZE, REACT_APP_DEFAULT_NAME_LENGTH, REACT_APP_SMALL_NAME_LENGTH } from "../config"
import { ToastAlert } from '../utils';
import { useEffect } from 'react';

const DownloadInstitutionCertificate = ({ user }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showActions, setShowActions] = useState(true);
    const [showAgainActions, setShowAgainActions] = useState(false);

    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [editName, setEditName] = useState(user.org_name);

    const editNameRef = useRef(null);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const downloadCertificate = async () => {
        try{
            setShowActions(false);
            setShowAgainActions(false);
            const downloadCertReq = await axios.get(`${REACT_APP_API_BASE_URL}user/download/institute`, config);
            if(downloadCertReq.status === 200){
                window.open(`${REACT_APP_API_BASE_URL}${downloadCertReq.data.data}`, '_blank');
                setShowAgainActions(true);
            }
            else{
                console.log("error");
                setShowActions(true);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const editInstituteName = async () => {
        setIsEditEnabled(true);
        editNameRef.current.focus();
    }

    const disableEditName = async () => {
        setIsEditEnabled(false);
    }

    const saveEditedName = async () => {
        // setIsEditEnabled(false);

        try{
            // setShowActions(false);
            // setShowAgainActions(false);

            const data = {
                name: editName
            }

            const saveEditedNameReq = await axios.patch(`${REACT_APP_API_BASE_URL}user/update/name`, data, config);
            if(saveEditedNameReq.status === 200){
                dispatch(updateOrganizationName({
                    name: editName
                }));
                ToastAlert.notifySuccess("Organization name updated!")
                setIsEditEnabled(false);
            }
            else{
                ToastAlert.notifySuccess("Error updating name. Please try again.")
                setIsEditEnabled(true);
            }
        }
        catch(error){
            console.log(error);
        }
    }
 

    return (
        <div className="adminDashboardPageContentMain">
            <div className="instCertDownloadContainer">
                <div className="instCertDownloadPreview">
                    <div className="instCertDownloadPreviewMain">
                        <div className="instCertDownloadPreviewImage">
                            {/* {
                                isEditEnabled === false
                                ?
                                    <div className="instCertDownloadPreviewName">
                                        <p className="instCertDownloadPreviewEnglishNameTxt" style={{
                                            fontSize: editName.length < 20 ? "1.5em" : "1.2em"
                                        }}>{user.org_name}</p>
                                    </div>
                                :
                                <></>
                            } */}
                            
                            {/* {
                                isEditEnabled === true
                                ?
                                    <div className="instCertDownloadPreviewInput">
                                        <input type="text" ref={editNameRef} className="instCertDownloadPreviewInputTxt" style={{
                                            fontSize: editName.length < 20 ? "1.5em" : "1.2em"
                                        }} placeholder="" value={editName} maxLength={35} onChange={(e) => setEditName(e.target.value)} />
                                    </div>
                                :
                                    <></>
                            } */}

                            <div className="instCertDownloadPreviewInput">
                                <input type="text" ref={editNameRef} className="instCertDownloadPreviewInputTxt" style={{
                                    fontSize: editName.length < REACT_APP_DEFAULT_NAME_LENGTH ? REACT_APP_DEFAULT_NAME_SIZE : REACT_APP_SMALL_NAME_SIZE
                                }} placeholder="" value={editName} maxLength={REACT_APP_SMALL_NAME_LENGTH} onChange={(e) => setEditName(e.target.value)} readOnly={isEditEnabled === false ? true : false} />
                            </div>
                            
                            <div className="instCertDownloadPreviewQrCode">
                                <img className="instCertDownloadPreviewQrCodeMain" src="/images/qr-code.png" />
                            </div>
                            <img className="instCertDownloadPreviewImg" src="/images/certificate-print.webp" />
                        </div>
                    </div>
                </div>
                
                {
                    showActions === true 
                    ?
                    <div className="publicDashboardDownloadCertActions">
                        <div className="publicDashboardDownloadCertActionsInner">
                            {
                                isEditEnabled === true
                                ?
                                    <>
                                        <button className="selfDeclarationPopUpContentAction agree" type="button" onClick={() => saveEditedName()}>save institute / school name</button>
                                        <button className="selfDeclarationPopUpContentAction primary" type="button" onClick={() => disableEditName()}>go back</button>
                                    </>
                                    
                                :
                                <>
                                    <button className="selfDeclarationPopUpContentAction primary" type="button" onClick={() => editInstituteName()}>edit institute / school name</button>
                                    <button className="selfDeclarationPopUpContentAction agree" type="button" onClick={() => downloadCertificate()}>download certificte</button>
                                </>
                            }
                            
                        </div>
                    </div>
                    :
                    <></>
                }

                {
                    showAgainActions === true 
                    ?
                    <div className="publicDashboardDownloadCertActions">
                        <div className="publicDashboardDownloadCertActionsInner">
                            <button className="selfDeclarationPopUpContentAction agree" type="button" onClick={() => downloadCertificate()}>download again</button>
                        </div>
                    </div>
                    :
                    <></>
                }
                
            </div>
        </div>
    )
}

export default DownloadInstitutionCertificate