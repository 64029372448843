import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from "../config"

const CertificatesPreviewPopUp = ({ user, certificatesConfirmed }) => {
    console.log(user);

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }

    const [disableSubmit, setDisableSubmit] = useState(false);

    const [participantsToPreview, setParticipantsToPreview] = useState([]);

    const getPreviewCertificatesHandler = async () => {
        try{
            const getPreviewCertificatesReq = await axios.get(`${REACT_APP_API_BASE_URL}participant/preview`, config);
            if(getPreviewCertificatesReq.status === 200){
                setParticipantsToPreview(getPreviewCertificatesReq.data.data);
            }
            else{
                console.log("error");
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getPreviewCertificatesHandler();
    }, []);

    useEffect(() => {
        console.log(participantsToPreview);
    }, [participantsToPreview]);

    const confirmCertificateHandler = () => {
        setDisableSubmit(true);
        certificatesConfirmed({status: true, participants: participantsToPreview})
    }

    return (
        <div className="certificatesPreviewPopUpWithBg">
            <div className="certificatesPreviewPopUp">
                <div className="certificatesPreviewPopUpHeading">
                    <p className="certificatesPreviewPopUpHeadingTxt">Preview Certificates</p>
                </div>
                <div className="certificatesPreviewPopUpContent">
                    <div className="certificatesPreviewPopUpContentInner">
                        <div className="certificatesPreviewPopUpIndivCerts">
                            <div className="certificatesPreviewPopUpIndivCertsMain">
                                {
                                    participantsToPreview.map((participant) => {
                                        return(
                                            <div className="certificatesPreviewPopUpIndivCertSingle" key={participant.id}>
                                                <div className="certificatesPreviewPopUpIndivCertSingleName">
                                                    <p className={`${
                                                        user.certificate_language === "KANNADA" 
                                                        ? 
                                                            "certificatesPreviewPopUpIndivCertSingleKnNameTxt" 
                                                        : 
                                                        user.certificate_language === "ENGLISH" 
                                                        ? 
                                                            "certificatesPreviewPopUpIndivCertSingleEnNameTxt" 
                                                        : 
                                                            "certificatesPreviewPopUpIndivCertSingleEnNameTxt" 
                                                        }`} style={{
                                                            fontSize: 
                                                                user.certificate_language === "KANNADA" ?
                                                                    participant?.name.length < 15 ? "1.2em" : "0.9em"
                                                                :
                                                                    participant?.name.length < 20 ? "1.8em" : "1.4em"
                                                        }}>
                                                        {participant.name}
                                                    ‌</p>
                                                </div>
                                                <div className="certificatesPreviewPopUpIndivCertSingleQrCode">
                                                    <img className="certificatesPreviewPopUpIndivCertSingleQrCodeImage" src="/images/qr-code.png" />
                                                </div>
                                                <div className="certificatesPreviewPopUpIndivCertSingleImage">
                                                    <img src="/images/certificate-print.webp" className="certificatesPreviewPopUpIndivCertSingleImg" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    disableSubmit === false
                    ?
                    <div className="certificatesPreviewPopUpActions">
                        <div className="certificatesPreviewPopUpActionsInner">
                            <button className="certificatesPreviewPopUpAction agree" type="button" onClick={() => confirmCertificateHandler()}>confirm</button>
                            <button className="certificatesPreviewPopUpAction cancel" type="button" onClick={() => certificatesConfirmed({status: false})}>cancel</button>
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default CertificatesPreviewPopUp