import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../reducers/User';

const PublicDashboardHeader = ({ user }) => {
    
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const logout = () => {
        dispatch(
            logoutUser()
        );
    }

    return (
        <div className="adminDashboardPageHeaderContainer">
            <div className="publicDashboardPageHeaderInner">
                <div className="adminDashboardPageHeaderEmblemPeople">
                    <div className="adminDashboardPageHeaderEmblem">
                        <div className="adminDashboardPageHeaderEmblemSingle">
                            <div className="adminDashboardPageHeaderEmblemSingleImage">
                                <img src="/images/state-emblem-header.jpg" className="adminDashboardPageHeaderEmblemSingleImg" alt="Govt. of Karnataka" />
                            </div>
                        </div>
                        <div className="adminDashboardPageHeaderEmblemSingle">
                            <div className="adminDashboardPageHeaderEmblemSingleImage">
                                <img src="/images/ambedkar-header.jpg" className="adminDashboardPageHeaderEmblemSingleImg" alt="B.R. Ambedkar" />
                            </div>
                        </div>
                    </div>

                    <div className="adminDashboardPageHeaderPeople">
                        <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-01.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-02.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-03.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        {/* <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-04.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div> */}
                        {/* <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-05.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div>
                        <div className="adminDashboardPageHeaderPeopleSingle">
                            <div className="adminDashboardPageHeaderPeopleSingleImage">
                                <img src="/images/leader-header-06.jpg" className="adminDashboardPageHeaderPeopleSingleImg" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="adminDashboardPageHeaderInfoActions">
                    <div className="adminDashboardPageHeaderInfo">
                        <div className="adminDashboardPageHeaderInfoHeading">
                            <p className="adminDashboardPageHeaderInfoHeadingTxt">dashboard</p>
                        </div>
                        <div className="adminDashboardPageHeaderInfoTagline">
                            <p className="adminDashboardPageHeaderInfoTaglineTxt">welcome, {user.person_name}!</p>
                        </div>
                    </div>

                    <div className="adminDashboardPageHeaderActions">
                        <div className="adminDashboardPageHeaderActionSingle active" onClick={() => navigate('/')}>
                            <i className="fa-solid fa-house adminDashboardPageHeaderActionSingleIco"></i>
                        </div>
                        <div className="adminDashboardPageHeaderActionSingle" onClick={logout}>
                            <i className="fa-solid fa-power-off adminDashboardPageHeaderActionSingleIco"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicDashboardHeader