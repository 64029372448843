import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser, markConfirmed, updateIDCardURL } from '../../reducers/User';
import { ToastAlert } from '../../utils';
import toast from 'react-hot-toast';
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from "../../config"

const ConfirmUserDetailsPage = ({ user }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [showFilePreview, setShowFilePreview] = useState(false)

    const handleFileSelection = async (e) => {
        setFile(e.target.files[0]);
        setFilePreview(Object.assign(e.target.files[0], {
            preview: URL.createObjectURL(e.target.files[0])
        }));
        setShowFilePreview(true);
    }

    const logout = () => {
        dispatch(logoutUser());
    }

    const confirm = async () => {
        if(user.id_card_url === null){
            if(user.certificate_type !== "individual"){
                if(filePreview === null){
                    ToastAlert.notifyError("ID Card is required");
                }
                else{
                    let loadingToast = toast.loading("Uploading ID Card... Please Wait!", {
                        position: "bottom-center"
                    });
        
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': user.access_token
                        }
                    }
        
                    var formData = new FormData();
                    formData.append(`image`, file);
        
                    axios.post(`${REACT_APP_API_BASE_URL}user/upload_id_card`, formData, config, {
                        onUploadProgress: (ProgressEvent) => {
                            let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                            console.log(progress);
                        }
                    }).then(res => {
                        const status = res.status;
                        if (status === 200) {
                            toast.dismiss(loadingToast);
                            ToastAlert.notifySuccess("Successfully Updated!");
                            dispatch(updateIDCardURL({
                                id_card_url: res.data.data.id_card_url
                            }));
                            dispatch(markConfirmed());
                        }
                        else{
                            toast.dismiss(loadingToast);
                            ToastAlert.notifyError("Failed To Update. Try Again Later!");
                        }
                    }).catch(err => {
                        toast.dismiss(loadingToast);
                        ToastAlert.notifyError("Failed To Update. Try Again Later!");
                    });
                }
            }
            else{
                dispatch(markConfirmed());
            }
        }
        else{
            dispatch(markConfirmed());
        }
    }

    const handleRemoveIDCard = () => {
        setFile(null);
        setFilePreview(null);
        setShowFilePreview(false);
    }

    console.log(user);

    return (
        <div className="preLoginPageContentMain">
            <div className="preLoginPageContentVisual">
                <div className="preLoginPageContentVisualImage">
                    <img src="/images/preamble.webp" className="preLoginPageContentVisualImg" alt="The Preamble" />
                </div>
            </div>
            <div className="preLoginPageContentInfoDesignedBy">
                <div className="preLoginPageContentInfo noBottomPadding">
                    <div className="preLoginPageContentInfoInner">
                        <div className="preLoginPageContentInfoHeading">
                            <p className="preLoginPageContentInfoHeadingTxt">Confirm Your Registration Details!</p>
                        </div>

                        <div className="preLoginPageContentInfoDetails">
                            <div className="preLoginPageContentInfoDetailsStep">
                                <div className="preLoginPageContentInfoDetailsStepLable">
                                    <p className="preLoginPageContentInfoDetailsStepLableTxt">registration ID</p>
                                </div>
                                <div className="preLoginPageContentInfoDetailsStepValue">
                                    <div className="preLoginPageContentInfoDetailsStepSep">
                                        <p className="preLoginPageContentInfoDetailsStepSepTxt">:</p>
                                    </div>
                                    <p className="preLoginPageContentInfoDetailsStepValueTxt">{user.reg_id}</p>
                                </div>
                            </div>
                            <div className="preLoginPageContentInfoDetailsStep">
                                <div className="preLoginPageContentInfoDetailsStepLable">
                                    <p className="preLoginPageContentInfoDetailsStepLableTxt">
                                        {
                                            user.org_type === "SCHOOL" ? "principal name" : 
                                            user.org_type === "COLLEGE" ? "college name" : 
                                            "contact person name"
                                        }
                                    </p>
                                </div>
                                <div className="preLoginPageContentInfoDetailsStepValue">
                                    <div className="preLoginPageContentInfoDetailsStepSep">
                                        <p className="preLoginPageContentInfoDetailsStepSepTxt">:</p>
                                    </div>
                                    <p className="preLoginPageContentInfoDetailsStepValueTxt">{user.person_name}</p>
                                </div>
                            </div>
                            <div className="preLoginPageContentInfoDetailsStep">
                                <div className="preLoginPageContentInfoDetailsStepLable">
                                    <p className="preLoginPageContentInfoDetailsStepLableTxt">
                                        {
                                            user.org_type === "SCHOOL" ? "school name" : 
                                            user.org_type === "COLLEGE" ? "college name" : 
                                            "organization name"
                                        }
                                    </p>
                                </div>
                                <div className="preLoginPageContentInfoDetailsStepValue">
                                    <div className="preLoginPageContentInfoDetailsStepSep">
                                        <p className="preLoginPageContentInfoDetailsStepSepTxt">:</p>
                                    </div>
                                    <p className="preLoginPageContentInfoDetailsStepValueTxt">{user.org_name}</p>
                                </div>
                            </div>
                            <div className="preLoginPageContentInfoDetailsStep">
                                <div className="preLoginPageContentInfoDetailsStepLable">
                                    <p className="preLoginPageContentInfoDetailsStepLableTxt">email ID</p>
                                </div>
                                <div className="preLoginPageContentInfoDetailsStepValue">
                                    <div className="preLoginPageContentInfoDetailsStepSep">
                                        <p className="preLoginPageContentInfoDetailsStepSepTxt">:</p>
                                    </div>
                                    <p className="preLoginPageContentInfoDetailsStepValueTxt emailTxt">{user.email_id}</p>
                                </div>
                            </div>
                            {
                                user.certificate_type === "institute"
                                ?
                                <div className="preLoginPageContentInfoDetailsStep">
                                    <div className="preLoginPageContentInfoDetailsStepLable">
                                        <p className="preLoginPageContentInfoDetailsStepLableTxt">no. of participants</p>
                                    </div>
                                    <div className="preLoginPageContentInfoDetailsStepValue">
                                        <div className="preLoginPageContentInfoDetailsStepSep">
                                            <p className="preLoginPageContentInfoDetailsStepSepTxt">:</p>
                                        </div>
                                        <p className="preLoginPageContentInfoDetailsStepValueTxt emailTxt">{user.no_of_participants}</p>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>

                        {
                            user.id_card_url === null
                            ?
                            <>
                                {
                                    user.certificate_type != "individual"
                                    ?
                                    <div className="preLoginPageContentInfoDetailsUploadID">
                                        <div className="preLoginPageContentInfoDetailsUploadIDMain">
                                            <div className="preLoginPageContentInfoDetailsUploadIDVisual">
                                                <div className="preLoginPageContentInfoDetailsUploadIDIcon">
                                                    <i className="fa-regular fa-id-badge preLoginPageContentInfoDetailsUploadIDIco"></i>
                                                </div>
                                                <div className="preLoginPageContentInfoDetailsUploadIDLable">
                                                    <i className="preLoginPageContentInfoDetailsUploadIDLableTxt">Upload <br />ID Card</i>
                                                </div>
                                            </div>
                                            <div className="preLoginPageContentInfoDetailsUploadIDInput">
                                                <input className="preLoginPageContentInfoDetailsUploadIDInputFile" type="file" accept="image/png, image/jpeg" value="" onChange={(e) => handleFileSelection(e)} />
                                            </div>
                                            {
                                                showFilePreview === true
                                                ?
                                                <div className="preLoginPageContentInfoDetailsUploadIDPreview">
                                                    <div className="preLoginPageContentInfoDetailsUploadIDPreviewRemove" onClick={handleRemoveIDCard}>
                                                        <i className="fa-solid fa-trash-can preLoginPageContentInfoDetailsUploadIDPreviewRemoveIco"></i>
                                                    </div>  
                                                    <div className="preLoginPageContentInfoDetailsUploadIDPreviewImage" style={{backgroundImage: `url(${filePreview.preview})`}}>&nbsp;</div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>

                                        <div className="preLoginPageContentInfoDetailsUploadIDInfoAction">
                                            <div className="preLoginPageContentInfoDetailsUploadIDInfo">
                                                <p className="preLoginPageContentInfoDetailsUploadIDInfoTxt">We require your authorized employee ID card to allow you to generate and download the offical certificates.</p>
                                                <p className="preLoginPageContentInfoDetailsUploadIDInfoTxt">By Uploading the ID Card you confirm the attachment is valid and any forgery to the document will be taken strict legal actions.</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                            :
                            <></>
                        }

                        <div className="preLoginPageContentInfoDetailsActions">
                            <div className="preLoginPageContentInfoDetailsActionsInner">
                                <p className="preLoginPageContentInfoDetailsActionNotUser" onClick={logout}>Not {user.person_name}? Logout.</p>
                                <button className="preLoginPageContentInfoDetailsAction confirm" onClick={confirm}>confirm & proceed</button>
                                {/* <button className="preLoginPageContentInfoDetailsAction reject" onClick={logout}>not you? logout</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="preLoginPageContentDesignedBy">
                    <div className="preLoginPageContentDesignedByInner">
                        <div className="preLoginPageContentDesignedByLable">
                            <p className="preLoginPageContentDesignedByLableTxt">designed &amp; developed by:</p>
                        </div>
                        <a href="https://ceg.karnataka.gov.in" target="_blank" rel="noreferrer" className="preLoginPageContentDesignedByImage">
                            <img src="/images/ceg.png" className="preLoginPageContentDesignedByImg" alt="CEG" />
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ConfirmUserDetailsPage