import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ParticipantsList from './ParticipantsList';
import NoParticipants from './NoParticipants';
import AddParticipants from './AddParticipants';
import { markHasNewData, markHasNoNewData } from '../reducers/User';
import CertificatesPreviewPopUp from './CertificatePreviewPopUp';
import { ToastAlert } from '../utils';
import toast from 'react-hot-toast';
import moment from 'moment';
import { REACT_APP_API_BASE_URL } from "../config"

const ManageParticipants = ({ user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const config = {
        headers: {
            'Authorization': user.access_token
        }
    }
    
    const [showAddParticipants, setShowAddParticipants] = useState(false);
    const [showNoParticipants, setShowNoParticipants] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);

    const [participants, setParticipants] = useState([]);
    const [maxParticipants, setMaxParticipants] = useState(user.no_of_participants);
    const [countTopUpload, setCountToUpload] = useState(0);

    const [newParticipantsCount, setNewParticipantsCount] = useState(0);

    const [showGenerateCertificate, setShowGenerateCertificate] = useState(false);
    const [showFirstTypeGeneration, setShowFirstTypeGeneration] = useState(false);

    const [showCertificatesPreviewPopUp, setShowCertificatesPreviewPopUp] = useState(false);
    const [certificates, setCertificates] = useState([]);

    const handleAddFirstParticiants = () => {
        setShowNoParticipants(false)
        setShowAddParticipants(true)
    }

    const addMoreParticipantsHandler = () => {
        setShowParticipants(false);
        setShowNoParticipants(false)
        setShowAddParticipants(true)
    }

    const getParticipants = async () => {

        try{
            const getParticipantsReq = await axios.get(`${REACT_APP_API_BASE_URL}participant/user/${user.id}`, config);
            if(getParticipantsReq.data.data.length > 0){
                setParticipants(getParticipantsReq.data.data);
                setShowParticipants(true);
                setShowAddParticipants(false);
                setShowNoParticipants(false);
                setCountToUpload(user.no_of_participants - getParticipantsReq.data.data.length);
            }
            else{
                setShowAddParticipants(false);
                setShowParticipants(false);
                setShowNoParticipants(true);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const participantsAddedHandler = (data) => {
        if(data.status === true){
            if(user.has_new_data === true){
                dispatch(markHasNoNewData());
            }
            else{
                dispatch(markHasNewData());
            }
        }
        else{
            getParticipants();
            setShowAddParticipants(false);
        }
    }

    useEffect(() => {
        getParticipants();
        getGeneratedCertificatesHandler();
    }, [user]);

    const checkCertificatesToGenerate = async () => {
        try{
            const checkCertificatesToGenerateReq = await axios.get(`${REACT_APP_API_BASE_URL}participant/generate/${user.id}`, config);
            if(checkCertificatesToGenerateReq.data.data.certificates_to_be_generated === true){
                setShowGenerateCertificate(true);
                console.log(participants.length);
                console.log(checkCertificatesToGenerateReq.data.data.count);
                setShowFirstTypeGeneration(participants.length - checkCertificatesToGenerateReq.data.data.count === 0 ? true : false);
                setNewParticipantsCount(checkCertificatesToGenerateReq.data.data.count);
            }
            else{
                setShowGenerateCertificate(false);
                setNewParticipantsCount(0);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        checkCertificatesToGenerate();
    }, [participants]);

    const checkGenerateEligibilityHandler = async () => {
        let eligble = false;
        try{
            const checkEligibilityReq = await axios.post(`${REACT_APP_API_BASE_URL}user/generate/eligiblity`, {}, config);
            if(checkEligibilityReq.status === 200){
                eligble = checkEligibilityReq.data.data.eligible;
                if(eligble === false){
                    console.log("Invaid Request");
                }
                else{
                    setShowCertificatesPreviewPopUp(true);
                }
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const certificatesConfirmedHandler = async (data) => {
        if(data.status === true){
            let loadingToast = toast.loading("Generating Certificates... Please Wait!", {
                position: "bottom-center"
            });

            const ids = [];
            if(data.participants.length > 0){
                data.participants.map(participant => {
                    ids.push(participant.id);
                });
            }
            console.log(ids);
            
            const req_data = {
                ids: JSON.stringify(ids)
            }

            const generateCertificatesReq = await axios.post(`${REACT_APP_API_BASE_URL}participant/generate`, req_data, config);
            if(generateCertificatesReq.status === 200){
                toast.dismiss(loadingToast);
                setShowCertificatesPreviewPopUp(false);
                ToastAlert.notifySuccess("Certificates Generated!");
                if(user.has_new_data === true){
                    dispatch(markHasNoNewData());
                }
                else{
                    dispatch(markHasNewData());
                }
            }
            else{
                toast.dismiss(loadingToast);
                ToastAlert.notifyError("Something went wrong! Try again later.");
            }
        }
        else{
            setShowCertificatesPreviewPopUp(false);
        }
    }

    const getGeneratedCertificatesHandler = async () => {
        try{
            const certificates = await axios.get(`${REACT_APP_API_BASE_URL}user/generated`, config);
            if(certificates.status === 200 && certificates.data.data.certificates.length > 0){
                setCertificates(certificates.data.data.certificates);
                console.log(certificates.data.data.certificates);
            }
            else{
                console.log("No certificates found!");
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const markCertificateDownloaded = async (status, c_id) => {
        if(status === false){
            try{
                const markDownloadedReq = await axios.patch(`${REACT_APP_API_BASE_URL}participant/mark/downloaded/${c_id}`, {}, config);
                if(markDownloadedReq.status === 200){
                    if(user.has_new_data === true){
                        dispatch(markHasNoNewData());
                    }
                    else{
                        dispatch(markHasNewData());
                    }
                }
                else{
                    console.log("error");
                }
            }
            catch(error){
                console.log(error);
            }
        }
        else{
            return
        }
    }

    return (
        <>
            <div className="adminDashboardPageContentMain">
                <div className="adminDashboardPageContentHeading">
                    <p className="adminDashboardPageContentHeadingTxt">manage participants</p>
                </div>

                <div className="manageParticipantsContainer">
                    {
                        showNoParticipants 
                        ?
                            <NoParticipants count={user.no_of_participants} action={() => handleAddFirstParticiants()} />
                        :
                        <></>
                    }
                    {
                        showAddParticipants
                        ?
                            <AddParticipants user={user} participantsAdded={(e) => participantsAddedHandler(e)} />
                        :
                        <></>
                    }
                    {
                        showParticipants
                        ?
                            <ParticipantsList participants={participants} user={user} max_participants={maxParticipants} count_remaining={countTopUpload} addmore={addMoreParticipantsHandler} />
                        :
                            <></>
                    }
                </div>
            </div>

            {
                showGenerateCertificate
                ?
                <div className="adminDashboardPageContentMain sectionMargin">
                    <div className="adminDashboardPageContentHeading">
                        <p className="adminDashboardPageContentHeadingTxt">generate certificate</p>
                    </div>
                    <div className="manageParticipantsContainer">
                        {
                            showFirstTypeGeneration
                            ?
                            <div className="noDataContainer">
                                <div className="noDataContainerInner">
                                    <div className="noDataContainerIcon">
                                        <i className="fa-regular fa-face-smile noDataContainerIco"></i>
                                    </div>
                                    <div className="noDataContainerHeading">
                                        <p className="noDataContainerHeadingTxt">Your Certificates Are Ready!</p>
                                    </div>
                                    <div className="noDataContainerDescription">
                                        <p className="noDataContainerDescriptionTxt">We have got your certificates ready. Click the below button to preview your certificates & proceed to download.</p>
                                    </div>
                                    <div className="noDataContainerActions">
                                        <button className="noDataContainerAction" onClick={() => checkGenerateEligibilityHandler()}>Preview Certificates</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="noDataContainer">
                                <div className="noDataContainerInner">
                                    <div className="noDataContainerIcon">
                                        <i className="fa-regular fa-face-meh noDataContainerIco"></i>
                                    </div>
                                    <div className="noDataContainerHeading">
                                        <p className="noDataContainerHeadingTxt">Certificates Are Not Generated For Few Participants!</p>
                                    </div>
                                    <div className="noDataContainerDescription">
                                        <p className="noDataContainerDescriptionTxt">You have a few participants for whom the certificates are not yet generated.<br />Click the below button to generate & download the certificates for participation.</p>
                                    </div>
                                    <div className="noDataContainerActions">
                                        <button className="noDataContainerAction" onClick={() => checkGenerateEligibilityHandler()}>Generate Certificate</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <></>
            }

            {
                certificates.length > 0 
                ?
                <div className="adminDashboardPageContentMain sectionMargin">
                    <div className="adminDashboardPageContentHeading">
                        <p className="adminDashboardPageContentHeadingTxt">download certificates</p>
                    </div>
                    <div className="adminDashboardPageContentTableContainer">
                        <div className="adminDashboardPageContentTable">
                            <div className="adminDashboardPageContentTableHeading">
                                <div className="adminDashboardPageContentTableHeadingSingle view">
                                    <p className="adminDashboardPageContentTableHeadingSingleLableTxt">SL NO.</p>
                                </div>
                                <div className="adminDashboardPageContentTableHeadingSingle name">
                                    <p className="adminDashboardPageContentTableHeadingSingleLableTxt">generated on</p>
                                </div>
                                <div className="adminDashboardPageContentTableHeadingSingle view">
                                    {/* <i className="fa-solid fa-check tableHeadingItemIco"></i> */}
                                </div>
                            </div>
                            <div className="adminDashboardPageContentTableRowContainer">
                                {
                                    certificates.map((certificate, i) => {
                                        return(
                                            <div className="adminDashboardPageContentTableRow" key={i}>
                                                <div className="adminDashboardPageContentTableRowSingle view center">
                                                    <div className="adminDashboardPageContentTableRowSingleLable">
                                                        <p className="adminDashboardPageContentTableRowSingleLableTxt">{i+1}</p>
                                                    </div>
                                                </div>
                                                <div className="adminDashboardPageContentTableRowSingle name">
                                                    <div className="adminDashboardPageContentTableRowSingleLable">
                                                        <p className="adminDashboardPageContentTableRowSingleLableTxt">{moment(new Date(certificate.createdAt)).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                    </div>
                                                </div>
                                                <div className="adminDashboardPageContentTableRowSingle view">
                                                    <div className="adminDashboardPageContentTableRowSingleIcon">
                                                        <a className="adminDashboardPageContentTableRowSingleIconInner" onClick={() => markCertificateDownloaded(certificate.is_downloaded, certificate.id)} href={`${REACT_APP_API_BASE_URL}${certificate.url}`} target="_blank">
                                                            <i className="fa-solid fa-download tableRowItemIco"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }

            {
                showCertificatesPreviewPopUp === true
                ?
                    <CertificatesPreviewPopUp user={user} certificatesConfirmed={(e) => certificatesConfirmedHandler(e)} />
                :
                    <></>
            }
        </>
    )
}

export default ManageParticipants