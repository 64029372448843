import React from 'react'
import Footer from '../components/Footer'
import PublicDashboardHeader from '../components/PublicDashboardHeader'
// import PublicDashboardProgressBanner from '../components/PublicDashboardProgressBanner'

const PublicDashboardLayout = ({ user, children }) => {
    return (
        <div className="adminDashboardPage">
            <PublicDashboardHeader user={user} />
            
            <div className="adminDashboardPageContentContainer">
                <div className="publicDashboardPageContentContainerInner">
                    {children}
                </div>
            </div>
        
            <Footer />
        </div>
    )
}

export default PublicDashboardLayout