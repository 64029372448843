import React from 'react'
import SelfDeclaration from '../../components/SelfDeclaration';
import SelectCertificateLanguage from '../../components/SelectCertificateLanguage';
import ManageParticipants from '../../components/ManageParticipants';

const InstituteIndividualDashboardPage = ({ user }) => {
    return (
        <>
            {
                user.is_self_declaration_signed === false
                ?
                    <SelfDeclaration user={user} />
                :
                user.certificate_language === "NOT_SET"
                ?
                    <SelectCertificateLanguage user={user} />
                :
                user.is_self_declaration_signed === true && user.certificate_language !== "NOT_SET"
                ?
                    <ManageParticipants user={user} />
                :
                    <></>
            }
        </>
    )
}

export default InstituteIndividualDashboardPage