import React from 'react'
import { useDispatch } from 'react-redux'
import { markCertificateType } from '../reducers/User'

const PreLoginChooseCertificateType = ({ user }) => {
    const dispatch = useDispatch();

    const handleSelectCertificateType = async (type) => {
        dispatch(markCertificateType({
            type: type
        }));
    }

    return (
        <div className="preLoginPageContentSection">
            <div className="preLoginPageContentSectionHeading">
                <p className="preLoginPageContentSectionHeadingTxt">Choose The Type of Certificates That Suites You.</p>
            </div>

            <div className="preLoginPageContentSectionContent">
                <div className="preLoginPageContentSectionContentCertTypes">
                    <div className="preLoginPageContentSectionContentCertTypeSingle">
                        <div className="preLoginPageContentSectionContentCertTypeSingleHeading">
                            <p className="preLoginPageContentSectionContentCertTypeSingleHeadingTxt">Institution Certificate</p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleSep">
                            <span className="preLoginPageContentSectionContentCertTypeSingleSepInner"></span>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleDesc">
                            <p className="preLoginPageContentSectionContentCertTypeSingleDescTxt">Institutions will be granted certificates upon successful validation. The institution's head will have the authority to download the Institution Certificate.</p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleActions">
                            <p className="preLoginPageContentSectionContentCertTypeSingleAction" onClick={() => handleSelectCertificateType("institute")}>generate</p>
                        </div>
                    </div>
                    <div className="preLoginPageContentSectionContentCertTypeSingle">
                        <div className="preLoginPageContentSectionContentCertTypeSingleHeading">
                            <p className="preLoginPageContentSectionContentCertTypeSingleHeadingTxt">Individuals Registered by the Institution</p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleSep">
                            <span className="preLoginPageContentSectionContentCertTypeSingleSepInner"></span>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleDesc">
                            <p className="preLoginPageContentSectionContentCertTypeSingleDescTxt">Individuals will also receive certificates following validation of the Institution, and they will be able to receive their certificates from their Institution Head.</p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleActions">
                            <p className="preLoginPageContentSectionContentCertTypeSingleAction" onClick={() => handleSelectCertificateType("institute_individual")}>generate</p>
                        </div>
                    </div>
                    <div className="preLoginPageContentSectionContentCertTypeSingle">
                        <div className="preLoginPageContentSectionContentCertTypeSingleHeading">
                            <p className="preLoginPageContentSectionContentCertTypeSingleHeadingTxt">Individuals who are not part of any Institution </p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleSep">
                            <span className="preLoginPageContentSectionContentCertTypeSingleSepInner"></span>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleDesc">
                            <p className="preLoginPageContentSectionContentCertTypeSingleDescTxt">Post validation, Individuals will be able to download their certificates based on their mobile number & registration ID.</p>
                        </div>
                        <div className="preLoginPageContentSectionContentCertTypeSingleActions">
                            <p className="preLoginPageContentSectionContentCertTypeSingleAction" onClick={() => handleSelectCertificateType("individual")}>generate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLoginChooseCertificateType