import React from 'react'

const Footer = () => {
    return (
        <div className="preLoginPageFooter">
            <div className="preLoginPageFooterInner">
                <p className="preLoginPageFooterLable">Copyright &copy; 2023, All Rights Reserved Centre for e-Governance, Karnataka.</p>
            </div>
        </div>
    )
}

export default Footer